import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Bienvenue à Seventic
			</title>
			<meta name={"description"} content={"Bienvenue chez Seventic, où votre voyage dans le monde numérique prend son envol."} />
			<meta property={"og:title"} content={"Bienvenue à Seventic"} />
			<meta property={"og:description"} content={"Bienvenue chez Seventic, où votre voyage dans le monde numérique prend son envol."} />
			<meta property={"og:image"} content={"https://frenchriviera.live/media/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://frenchriviera.live/media/551-5512749.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://frenchriviera.live/media/551-5512749.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://frenchriviera.live/media/551-5512749.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://frenchriviera.live/media/551-5512749.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://frenchriviera.live/media/551-5512749.png"} />
			<meta name={"msapplication-TileImage"} content={"https://frenchriviera.live/media/551-5512749.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" quarkly-title="Hero-7">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="120px 50px 120px 50px"
					lg-width="100%"
					lg-padding="80px 50px 90px 50px"
					sm-padding="80px 25px 90px 25px"
					lg-display="flex"
					lg-flex-direction="column"
				>
					<Text margin="0px 0px 20px 0px" font="normal 700 56px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Bienvenue à Seventic
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="#8b9197">
						Bienvenue chez Seventic, où votre voyage dans le monde numérique prend son envol. Chez Seventic, nous sommes plus qu'une simple agence de marketing numérique - nous sommes les architectes de votre réussite en ligne. Notre engagement en faveur de l'innovation stratégique, de campagnes sur mesure et d'un impact mesurable nous distingue dans le paysage en constante évolution du marketing numérique. Rejoignez-nous pour découvrir l'essence de Seventic et embarquer pour un voyage transformateur afin d'élever la présence de votre marque dans la sphère numérique.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-items="stretch"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="12px 28px 12px 28px"
							background="#3f24d8"
							border-radius="8px"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-width="2px"
							border-style="solid"
							border-color="#3f24d8"
							hover-color="--darkL1"
							href="/contacts"
							type="link"
							text-decoration-line="initial"
							hover-background="rgba(63, 36, 216, 0)"
						>
							Entrer en contact
						</Button>
					</Box>
				</Box>
				<Image
					src="https://frenchriviera.live/media/1.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
				/>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Découvrez l'essence de Seventic
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Chez Seventic, nous tissons une tapisserie de réussites numériques. Grâce à notre engagement en faveur de stratégies axées sur les résultats et à notre compréhension approfondie du paysage numérique en constante évolution, nous ne sommes pas seulement une agence de marketing - nous sommes vos partenaires pour naviguer dans le monde dynamique de la présence en ligne.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://frenchriviera.live/media/2.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://frenchriviera.live/media/3.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Pourquoi choisir Seventic
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					- Une vision stratégique : Notre équipe de professionnels chevronnés apporte une grande expérience, garantissant que vos stratégies numériques ne sont pas seulement actuelles mais aussi à la pointe du progrès.
					<br />
					<br />
					- Des campagnes sur mesure : Il n'y a pas de solution unique. Nous adaptons nos approches à vos objectifs uniques, en amplifiant la voix de votre marque et en atteignant le bon public.
					<br />
					<br />
					- Un impact mesurable : L'importance que nous accordons à l'analyse et à la prise de décision fondée sur les données garantit que vos investissements marketing se traduisent par des résultats tangibles et mesurables.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Révéler l'avantage Seventic
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					- Brillance créative : Engagez votre public avec un contenu visuellement frappant et percutant, conçu pour captiver et convertir.
					<br />
					<br />
					- Des solutions à la pointe de la technologie : Bien que nous restions à l'écart des mots à la mode, notre approche technologique en coulisses garantit que vos campagnes tirent parti des dernières nouveautés sans perdre le contact avec votre public.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://frenchriviera.live/media/4.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});